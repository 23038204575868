<template>
  <p class="text-rating whitespace-nowrap">
    <app-icon
      v-for="i in steps"
      :key="i"
      aria-hidden="true"
      :icon="iconPerStep(i)"
      :size="size"
    />
  </p>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    value: number;
    size?: number;
    steps?: number;
  }>(),
  {
    steps: 5,
    size: 24,
  },
);

const iconPerStep = (step: number) => {
  if (properties.value >= step - 1 + 0.4 && properties.value < step - 0.2)
    return "ph:star-half-fill";

  if (properties.value > step - 1 + 0.5) return "ph:star-fill";

  return "ph:star";
};
</script>
